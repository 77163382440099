//MENU OPEN

function initBurger() {
    const burger = document.getElementById('burger');
    const menu = document.getElementById('menu');
    let scrollY;

    burger.addEventListener('click', () => {
        menu.classList.add('open');
        scrollY = window.scrollY;
        window.addEventListener('scroll', disableScroll);
    });

    const burgerBack = document.getElementById('burgerBack');

    burgerBack.addEventListener('click', () => {
        menu.classList.remove('open');
        window.removeEventListener('scroll', disableScroll);
    });

    function disableScroll() {
        window.scrollTo({
            top: scrollY
        });
    }
}

//HEADER SHRINK & HIDE

function initHeaderShrink(body, html) {
    const footer = document.getElementById('footer');
    const header = document.getElementById('header');
    const offset = 10;

    function hideHeader() {
        var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

        //hide
        if (height - window.scrollY - window.innerHeight < footer.offsetHeight + offset) {
            header.classList.add('hide');
            menu.classList.remove('open');
        }
        else {
            header.classList.remove('hide');
        }

        //shrink
        if (window.scrollY > 75) {
            body.classList.add('smallHeader');
        }
        else {
            body.classList.remove('smallHeader');
        }
    }

    window.addEventListener('scroll', hideHeader);
}

//CUSTOM CURSOR

function initCursor() {
    jQuery(document).ready(function() {
        var mouseX = 0, mouseY = 0;
        var xp = 0, yp = 0, xpd = 0, ypd = 0, circleSize = 15;
        const circle = $('#circle');
        const dot = $('#dot');
        let scrolledY = $(window).scrollTop();
    
        $('body').addClass('noCursor');

        $(window).scroll(function (event) {
            scrolledY = $(window).scrollTop();
        });
         
        $(document).mousemove(function(e){
          mouseX = e.pageX;
          mouseY = e.pageY - scrolledY;
        });
          
        setInterval(function(){
          xp += ((mouseX - xp - circleSize)/8);
          yp += ((mouseY - yp - circleSize)/8);
    
          xpd += ((mouseX - xpd - 4)/1.5);
          ypd += ((mouseY - ypd - 4)/1.5);
          circle.css({left: xp +'px', top: yp +'px'});
          dot.css({left: xpd +'px', top: ypd +'px'});
        }, 2);
    });
    
    //EL SUR LESQUELS IL S'APPlIQUE
    
    document.querySelectorAll('a').forEach((el) => {
        el.addEventListener('mouseenter', growCursor);
        el.addEventListener('mouseleave', shrinkCursor);
    });
    
    document.querySelectorAll('.whiteCursor').forEach((el) => {
        el.addEventListener('mouseenter', whiteCursor);
        el.addEventListener('mouseleave', unWhiteCursor);
    });
        
    document.querySelectorAll('a.listing__item').forEach((el) => {
        el.addEventListener('mouseenter', projectCursor);
        el.addEventListener('mouseleave', unProjectCursor);
    })
    
    document.querySelectorAll('a.listing__item--actu').forEach((el) => {
        el.addEventListener('mouseenter', actuCursor);
        el.addEventListener('mouseleave', uneActuCursor);
    })

    document.querySelectorAll('a.figure').forEach((el) => {
        el.addEventListener('mouseenter', figureCursor);
        el.addEventListener('mouseleave', unFigureCursor);
    })

    document.querySelectorAll('a.video').forEach((el) => {
        el.addEventListener('mouseenter', videoCursor);
        el.addEventListener('mouseleave', unVideoCursor);
    })
    
    function growCursor(){
        circle.classList.add('grow');
    }
    
    function shrinkCursor(){
        circle.classList.remove('grow');
    }
    
    
    function whiteCursor(){
        circle.classList.add('white');
    }
    
    function unWhiteCursor(){
        circle.classList.remove('white');
    }
    
    function projectCursor(){
        circle.classList.add('project');
    }
    
    function actuCursor(){
        circle.classList.add('actu');
    }
    function uneActuCursor(){
        circle.classList.remove('actu');
    }
    
    function unProjectCursor(){
        circle.classList.remove('project');
    }

    function figureCursor(){
        circle.classList.add('figure');
    }
    
    function unFigureCursor(){
        circle.classList.remove('figure');
    }

    function videoCursor(){
        circle.classList.add('video');
    }
    
    function unVideoCursor(){
        circle.classList.remove('video');
    }
}

//SCROLLDOWN

function initScrollDown() {
    const scrollDown = document.getElementById('scrollDown');

    if (scrollDown) {
        scrollDown.addEventListener('click', () => {
            window.scrollTo({
                top: window.innerHeight,
                behavior: 'smooth'
            })
        })
    }
}

//SLIDER

function initSlider() {
    const slider = document.getElementById('slider');
    const slides = slider.querySelectorAll('img');
    const slidesMax = slides.length;
    let current = 0;

    let sliderInterval = setInterval(() => {
        slides[current].classList.remove('show');
        current++;
        if(current >= slidesMax){
            current = 0
        }
        slides[current].classList.add('show');
    }, 4000);
}

//IMG CLICK

function initImageClick() {
    const imagesClick = document.querySelectorAll('img[data-enlargeable]');
    const nav = document.getElementById('modalNav');
    const prev = document.getElementById('modalPrev');
    const next = document.getElementById('modalNext');

    $('img[data-enlargeable]').click(function(e){
        var src = $(this).attr('src');
        var modal;
        var indexImagesClick;
        imagesClick.forEach((el, index) => {
           if(el.getAttribute('src') == $(this).attr('src')){
                indexImagesClick = index;
           };
        });
        nav.classList.add('show');
        next.addEventListener('click', navModalNext);
        prev.addEventListener('click', navModalPrev);
        document.body.addEventListener('keydown', keyDown);
        function keyDown(e){ 
            e.preventDefault();
            var keynum;
            if(window.event) { // IE                    
              keynum = e.keyCode;
            } else if(e.which){ // Netscape/Firefox/Opera                   
              keynum = e.which;
            } 

            if(keynum == 37 || keynum == 38){
                navModalPrev();
            }
            if(keynum == 39 || keynum == 40){
                navModalNext();
            }
        };
        function removeModal(){ modal.remove(); $('body').off('keyup.modal-close'); nav.classList.remove('show'); next.removeEventListener('click', navModalNext); prev.removeEventListener('click', navModalPrev);}
        function navModalNext(){ modal.remove(); indexImagesClick++; if(indexImagesClick == imagesClick.length){ indexImagesClick = 0 }; createModal(imagesClick[indexImagesClick].getAttribute('src'));}
        function navModalPrev(){ modal.remove(); indexImagesClick--; if(indexImagesClick == -1){ indexImagesClick = imagesClick.length -1 }; createModal(imagesClick[indexImagesClick].getAttribute('src'));}
        function createModal(source) {
            modal = $('<div>').css({
                background: 'rgba(19, 30, 75, .8) url('+source+') no-repeat center',
                backgroundSize: 'contain',
                width:'100%', height:'100%',
                position:'fixed',
                zIndex:'100',
                top:'0', left:'0',
                cursor: 'zoom-out'
            }).click(function(){
                removeModal();
            }).appendTo('body');
        }

        createModal(src);
        //handling ESC
        $('body').on('keyup.modal-close', function(e){
          if(e.key==='Escape'){ removeModal(); } 
        });
    });
}

//VIDEO CLICK

function initVideoClick() {
    const modal = document.getElementById('video-modal');

    if(!modal) return;

    const frame = modal.querySelector('iframe');

    let videosTriggers = document.querySelectorAll('a.video');

    videosTriggers.forEach((el) => {
        el.addEventListener('click', () => {
            modal.classList.add('active');
            frame.src = el.getAttribute('data-src');
        })
    })

    modal.addEventListener('click', () => {
        modal.classList.remove('active');
        frame.src = null;
    });
}

jQuery(document).ready(() => {
    const body = document.body, html = document.documentElement;
    AOS.init();

    if(bodyHasClass(body, 'page-template-page-les-projets')){
        initPagination();
    }
    
    if(window.innerWidth > 1200){
        initCursor();
    }

    if(window.innerWidth > 767){
        initImageClick();
    }

    initBurger();
    initHeaderShrink(body, html);
    initVideoClick();
    if(bodyHasClass(body, 'home')){
        initSlider();
        initScrollDown();
        initLogoSlider();
    }

    if(bodyHasClass(body, 'page-id-125')){
        initSlider();
    }
    if(bodyHasClass(body, 'page-id-7')){
        initSlider();
    }
});

function bodyHasClass(el, cls) {
    return el.className.match(new RegExp('(\\s|^)'+cls+'(\\s|$)'));
}


// LES PROJETS




function initPagination() {
    const projects = document.querySelectorAll('.listing__item');
    const projectsContainer = document.querySelector('.listing__container');
    const pagination = document.getElementById('pagination');
    const paginationContainer = pagination.querySelector('ul');
    const filters = document.querySelectorAll('.listing__filters li');

    createPagination();
    setupFilters();

    if (window.location.search.indexOf("filtre") >= 0){
        hideAllProjectsBut(-1);
        projects.forEach((el) => {
            if(el.classList.contains('show')){
                projectsContainer.appendChild(el);
                setTimeout(() => {
                    el.classList.remove('hide');
                    el.classList.remove('show');
                }, 50);
            }    
        });
    }
    else{
        hideAllProjectsBut(1);
    }

    function createPagination() {
        const pageNumber = Math.ceil(projects.length/9);
        
        if(pageNumber > 1){
            for(var i=1; i <= pageNumber; i++){
                let el = document.createElement('a');
                if(i == 1){
                    el.classList.add('active');
                }
                el.innerHTML = i;
                el.setAttribute('data-page', i);
                el.setAttribute('href', '#les-projets');
                paginationContainer.appendChild(el);
                el.addEventListener('click', switchPage);
            }
        } else{
            pagination.classList.add('hide');
        }
    }

    function setupFilters() {
        
        

        window.addEventListener('load' , function(){
            if(localStorage.getItem('activeFilter')){
                document.getElementById('filter-'+localStorage.getItem('activeFilter')).click();
            }
        });
        filters.forEach((el) => {
            el.addEventListener('click', () => {
                hideAllProjectsBut(-1, el.querySelector('a').getAttribute('data-filter'));
                removeActiveFilter();
                el.classList.add('active');
                localStorage.setItem('activeFilter', el.querySelector('a').getAttribute('data-filter'));
            });
        })

        function removeActiveFilter(){
            filters.forEach((el) => {
                el.classList.remove('active');
            })
        }
    }

    function switchPage(e) {
        hideAllProjectsBut(e.target.getAttribute('data-page'));
        pagination.querySelector('a.active').classList.remove('active');
        e.target.classList.add('active');
    }

    function hideAllProjectsBut(page, filter) {
        projects.forEach((el) => {
            el.classList.add('hide');
            if(el.getAttribute('data-page') == page || el.classList.contains(filter) || (filter == "all" && el.getAttribute('data-page') == 1)){
                projectsContainer.appendChild(el);
                setTimeout(() => {
                    el.classList.remove('hide');
                }, 50);
            }
            else{
                if(projectsContainer.contains(el)){
                    projectsContainer.removeChild(el);
                }      
            } 
        });

        let paginationLinks = pagination.querySelectorAll('a');

        if(filter){
            paginationLinks.forEach((el) => {
                el.classList.add('active');
            })
        }
        if(filter == "all"){
            paginationLinks.forEach((el) => {
                el.classList.remove('active');
            })
            paginationLinks[0].classList.add('active');
        }
    }
}

// LOGO SLIDER

function initLogoSlider() {
    $('#logo-slider').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        prevArrow: false,
        nextArrow: false,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
        ]
    });
}

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});